<template>
    <div style="width: 100%; height: 100%;" v-loading="true" element-loading-text="拼命加载中"></div>
</template>

<script>
export default {
    data() {
        return {
            wecom: {
                oauth2: 'https://open.weixin.qq.com/connect/oauth2/authorize',
                corp_id: 'ww9f2d2336a56bf6e3',
                agent_id: '1000134',
                redirect_uri: 'https://aqyy.waner.cn/auth/login',
                state: 'web_login',
                scope: 'snsapi_privateinfo',
                code: null,
            },
        }
    },

    mounted() {

        // 浏览器 - 获取标识头
        if (this.$route.query.code) {
            this.wecom.code = this.$route.query.code
            this.SubmitLoginWecom()
        } else {
            // 浏览器 - 获取标识头
            var ua = window.navigator.userAgent.toLowerCase();
            window.location.href = this.wecom.oauth2 + '?appid=' + this.wecom.corp_id + '&redirect_uri=' + encodeURIComponent(this.wecom.redirect_uri) + '&response_type=code' + '&scope=' + this.wecom.scope + '&state=' + this.wecom.state + '&agentid=' + this.wecom.agent_id + '#wechat_redirect'
        }
    },

    methods: {
        // 企业微信登入
        SubmitLoginWecom() {

            this.$http.post('/9api/login-wecom', { code: this.wecom.code }).then((rps) => {
                // 记录token
                sessionStorage.setItem('token', rps.data)

                // 即将跳转的页面
                let path = sessionStorage.getItem('pathTo') ?? '/'
                let link = path != '/auth/login' ? path : '/'
                
                // 移动端校验
                if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                    let url = new URL('https://aqyy_m.waner.cn' + link)
                    url.searchParams.set('token', rps.data)
                    url.searchParams.set('time', new Date().getTime())
                    alert(url.toString())
                    window.location.href = url.toString()
                } else {
                    this.$router.push(link);
                }
            })
        }
    }
}
</script>